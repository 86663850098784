<template>
    <div class="card-list-wrapper">
        <div class="card-list" v-if="dataList.length > 0">
            <ul class="flex-align-between card-ul">
                <li v-for="(item, index) in dataList" :key="item.id" class="card csp" @click.prevent="toDetail(item, index)">
                    <div class="status flex-align-between">
                        <!-- 我的课程 -->
                        <div class="kind" v-if="cardType === 'course'">
                            <img class="course-type-image"
                                :src="item.lessonsPattern === 0 ? require('@/assets/images/course/syncourse.png') : require('@/assets/images/course/asyncourse.png')"
                                alt="" />
                        </div>
                        <!-- 我的作业 -->
                        <div class="kind-type"
                            :class="item.themeType === 1 ? 'background-color-1' : item.themeType === 2 ? 'background-color-2' : 'background-color-3'"
                            v-if="cardType === 'Task'">{{ item.themeType === 1 ? '作业' : item.themeType === 2 ? '测试' : '问卷' }}
                        </div>
                        <!-- 我的活动 -->
                        <div class="kind-type" :class="item.themeType === 1 ? 'background-color-2' : 'background-color-3'"
                            v-if="cardType === 'Activity'">{{ item.themeType === 1 ? '作业主题' : '活动' }}</div>
                        <!-- 右上角标签 -->
                        <div class="kind" v-if="cardType == 'Activity'">
                            <img class="course-type-image" :src="acitvityTypeImg[item.status]" alt="" />
                        </div>
                        <div class="kind" v-if="cardType == 'Task'">
                            <img class="course-type-image" :src="workTypeImg[item.status]" alt="" />
                        </div>
                    </div>
                    <div class="img-wrapper">
                        <img :src="downloadURL + item.cover + '&width=270'"
                            :onerror="$store.state.course.defaultCourseCover[index % 6]" alt v-if="cardType === 'course'" />

                        <img :src="downloadURL + item.themeCover" :onerror="$store.state.course[`default${cardType}Cover`]"
                            alt v-if="cardType === 'Task'" />
                        <img :src="downloadURL + item.cover" :onerror="$store.state.course[`default${cardType}Cover`]" alt
                            v-if="cardType === 'Activity'" />
                    </div>
                    <div class="flex-column-between info">
                        <h3 class="title wes-2">{{ item.name || item.themeName }}</h3>
                        <!-- 我的课程 -->
                        <!-- @click.stop="toDetail(item,index)" -->
                        <div v-if="cardType === 'course'">
                            <div class="speaker wes">主讲老师：{{ item.teacherName }}</div>
                            <div class="time">
                                上课时间：{{ item.timeScope || '--' }}
                            </div>
                        </div>
                        <!-- 我的作业 -->
                        <!-- @click.prevent="toDetail(item)" -->
                        <div v-else-if="cardType === 'Task'">
                            <div class="time flex-align-between">
                                <span>作业共计：{{ item.questCount }}个</span>
                                <span style="margin-right:35px;">已完成：{{ (item.questCount - item.undone) || 0 }}个</span>
                            </div>
                            <div class="time">
                                截止时间：{{ item.endRestriction }}
                            </div>
                            <!-- <div class="integral">积分：{{item.score}}分</div> -->
                        </div>
                        <!-- 我的活动 -->
                        <!-- @click.prevent="toDetail(item)" -->
                        <div v-else-if="cardType === 'Activity'">
                            <div class="time">
                                <!-- 报名截止时间：{{dayjs(item.applyEndTime).format('YYYY-MM-DD HH:mm')}} -->
                                活动开始时间：{{ dayjs(item.startTime).format('YYYY-MM-DD HH:mm') }}
                                <br>
                                活动结束时间：{{ dayjs(item.endTime).format('YYYY-MM-DD HH:mm') }}
                            </div>
                        </div>
                    </div>
                </li>
                <li class="placeholder-card card"></li>
            </ul>
            <pagination v-show="total > 0" :total="total" :page.sync="pages.currentPage" :limit.sync="pages.pageSize"
                :pageSizes="[3, 6, 9]" @pagination="handlePageChange">
            </pagination>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        cardType: {
            type: String,
            default: 'course'
        },
        dataList: {
            type: Array,
            default: function () {
                return []
            },
        },
        total: {
            type: Number,
            default: 0
        },
        proid: {
            type: String,
            default: 0
        }
    },
    data() {
        return {
            pages: { //分页
                currentPage: 1,
                pageSize: 6,
                //   total:0,
            },
            courseTypeImg: { //课程 右上角图标 对照表
                0: require("@/assets/images/teach/signin-number.png"),
                1: require("@/assets/images/teach/signin-number.png")
            },
            workTypeImg: { //作业 右上角图标 对照表
                // 0:'未开始',
                // 1:'进行中',
                // 2:'已结束'
                0: require("@/assets/images/course/notstart.png"),
                1: require("@/assets/images/course/start.png"),
                2: require("@/assets/images/course/end.png")
            },
            acitvityTypeImg: { //活动、作业 右上角图标 对照表
                // 1:'未开始',
                // 2:'进行中',
                // 3:'已结束'
                1: require("@/assets/images/course/notstart.png"),
                2: require("@/assets/images/course/start.png"),
                3: require("@/assets/images/course/end.png")
            },
        }
    },
    watch: {
        proid(newVal, oldVal) {
            // console.log('proid---newVal',newVal)
            if (!newVal || !oldVal) return
            this.pages.currentPage = 1;
            this.pages.pageSize = 6;
        },
    },
    methods: {
        // 翻页
        handlePageChange(data) {
            this.pages.currentPage = data.pageIndex
            this.pages.pageSize = data.pageSize
            // console.log(this.pages);
            this.$emit('returnPagesData', {
                currentPage: this.pages.currentPage,
                pageSize: this.pages.pageSize
            })
        },
        // 去详情页
        toDetail(item, index) {
            console.log(item);
            // this.dialogVisible = true
            // this.$router.push('/LoginJm')
            // let _coverIndex = index % 5
            if (item.id == 2789) {
                // this.$router.push('/LoginJm')
                window.open("https://jm.cnuen.net/login/third")
            } else if (item.id == 2788) {
                window.open("https://basic.smartedu.cn/syncClassroom/classActivity?activityId=114fd2ce-4ad5-47fb-adb1-45ab656bd91f")
            } else {
                let map = {
                    'course': {
                        path: `/course/${item.id}`,
                        query: {
                            // proid: this.proid,
                            index: index,
                            breadNumber: 4,
                        }
                    },
                    'Task': {
                        path: `/mytask/detail/${item.id}`,
                        query: {
                            breadNumber: 4,
                            // proid: this.proid,
                            // bindingId: item.bindingId,
                            // theme: item.themeName
                        }
                    },
                    'Activity': {
                        path: `/newActiveDetail/${item.id}`,
                        query: {
                            breadNumber: 4,
                        }
                    }
                }

                this.$router.push({
                    path: map[`${this.cardType}`].path,
                    query: map[`${this.cardType}`].query
                });
            }

        },
    },
    mounted() { },
    destroyed() { },
}
</script>

<style lang="less" scoped>
.card-list-wrapper {

    .card-list {
        // padding: 15px 16px;
    }

    .card-ul {
        flex-flow: row wrap;

        .card {
            position: relative;
            box-sizing: border-box;
            margin: 0 0px 22px 0;
            border-radius: 10px;
            padding: 0 0 10px 0;
            width: 31.7%;
            border: 1px solid #e8e8e8;
        }

        .placeholder-card {
            height: 0;
            padding: 0 !important;
            margin: 0 !important;
            border: 0;
        }

        //li上部分
        .status {
            box-sizing: border-box;

            .kind {
                position: absolute;
                top: 0;
                right: 0;

                .course-type-image {
                    width: 50px;
                }
            }

            .kind-type {
                position: absolute;
                top: 14px;
                left: 0;

                text-align: center;
                line-height: 24px;
                font-size: 14px;
                padding: 2px 12px 2px 7px;
                height: 24px;
                border-radius: 2px 12px 12px 2px;
                color: #ffffff;
                // color: #2474ec;
            }

            .background-color-1 {
                background: #F6B926;
            }

            .background-color-2 {
                background: #36C2CF;
            }

            .background-color-3 {
                background: #EF5757;
            }

            .countdown {
                color: #ff1a1a;
            }
        }

        //中间图片
        .img-wrapper {
            width: 100%;
            height: 128px;
            margin: 0px auto 5px;
            overflow: hidden;
            border-radius: 3px;

            img {
                width: 100%;
                height: 100%;
                max-width: 100%;
                border-top-left-radius: 8px;
                border-top-right-radius: 8px;
            }
        }


        //li下部分
        h3.title {
            margin-bottom: 5px;
            height: 38px;
            font-size: 13px;
            padding: 0 11px;
            font-weight: bold;
        }


        .speaker,
        .integral,
        .time {
            padding-bottom: 3px;
            color: #595959;
            font-size: 12px;
            padding: 0 11px;
            line-height: 20px;
        }

        .other {
            margin-top: 10px;

            .otherJob {
                font-size: 15px;
                color: #888;
            }

            .toDetail {
                max-width: 119px;
                padding: 0 32px;
                text-align: center;
                background: linear-gradient(90deg, #2272ec, #3a7bf6);
                box-shadow: 0px 5px 13px 0px rgba(22, 115, 255, 0.28);
                border-radius: 5px;
                line-height: 31px;
                color: #ffffff;
            }
        }
    }

    @media screen and (min-width: 1250px) {
        .card-list {
            // padding: 20px 22px;
        }

        .card-ul {
            .card {
                margin: 0 0 23px 0;
                padding: 0 0 15px 0;
            }

            .img-wrapper {
                height: 155px;
                margin: 0px auto 10px;
            }

            h3.title {
                margin-bottom: 10px;
                font-size: 15px;
                height: 44px;
            }
        }
    }

}
</style>