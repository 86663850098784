//登录
const Login = () => import("../views/login/login.vue"); //登录
const LoginForgetPwd = () => import("../views/login/login_forget_pwd.vue"); //忘记密码
const LoginBindAccount = () => import("../views/login/login_bind_account.vue"); //绑定账户第三方账户
const LoginDingDingBindAccount = () => import("../views/login/login_dingding_bind_account.vue"); //绑定账户第三方账户
const LoginThird = () => import("../views/login/login_third.vue"); //对外第三方登录
const LoginJm = () => import("../views/login/login_jm.vue")
const UpdateUserInfo = () => import("../views/login/update_userInfo.vue"); // 虚拟账号完善资料页面
const UpdateUserInfoCloud = () => import("../views/login/update_userInfo_cloud.vue"); // 教育云账号完善资料页面

export default [
  {
    path: "/", //主路由重定向至company组件
    redirect: "/login"
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: {
      title: "登录",
      keepAlive: false,
      hasNotHeader:true,
    }
  },
  {
    path: "/login/forgetpwd",
    name: "forgetpwd",
    component: LoginForgetPwd,
    meta: {
      title: "忘记密码",
      keepAlive: false,
      hasNotHeader:true,
    }
  },
  {
    path: "/login/bindAccount",
    name: "loginBindAccount",
    component: LoginBindAccount,
    meta: {
      title: "关联账号",
      keepAlive: true,
      hasNotHeader:true,
    }
  },
  {
    path: "/login/bindYcDingDingAccount",
    name: "loginDingDingBindAccount",
    component: LoginDingDingBindAccount,
    meta: {
      title: "关联账号",
      keepAlive: true,
      hasNotHeader:true,
    }
  },
  {
    path: "/login/third",
    name: "LoginThird",
    component: LoginJm,
    meta: {
      title: "第三方登录",
      keepAlive: false,
      hasNotHeader:true,
    }
  },
  {
    path: "/login/LoginJm",
    name: "LoginJm",
    component: LoginJm,
    meta: {
        title: "第三方登录",
        keepAlive: false,
        hasNotHeader:true,
      }
  },{
    path: "/updateUserInfo",
    name: "updateUserInfo",
    component: UpdateUserInfo,
    meta: {
      title: "完善资料",
      keepAlive: false,
      hasNotHeader:true,
    }
  },{
    path: "/updateUserInfoCloud",
    name: "updateUserInfoCloud",
    component: UpdateUserInfoCloud,
    meta: {
      title: "完善资料",
      keepAlive: false,
      hasNotHeader:true,
    }
  }
];
