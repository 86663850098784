import Vue from "vue";
import App from "./App.vue";
import VueRouter from "vue-router";
import routes from "./router.js";
import store from "@/vuex/store";

import Config from './config.js'
Vue.prototype.Config = Config
//公共样式 
import '@/styles/index.scss' //作业部分
import '@/icons' //作业部分

// 引入m3u8插件
import 'video.js/dist/video-js.css'

// 插件
import axios from 'axios'
Vue.prototype.$axios = axios

import dayjs from 'dayjs';
Vue.prototype.dayjs = dayjs;

import QRCode from 'qrcodejs2'
Vue.prototype.QRCode = QRCode

import * as echarts from 'echarts'
import 'echarts-wordcloud'
Vue.prototype.$echarts = echarts

import Tinymce from '@/components/tinymce/index.vue'
Vue.component('tinymce', Tinymce)

import Tab from './views/tab.vue'
Vue.component('myTab', Tab)

import VueWechatTitle from 'vue-wechat-title'
Vue.use(VueWechatTitle);

import Recorder from 'js-audio-recorder'
// Vue.use(Recorder);


// 图片预览插件
import Preview from "@/components/imgviewer/index"
Vue.use(Preview);

import Element from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
Vue.use(Element);

import Cookies from "js-cookie";
window.Cookies = Cookies;
Cookies.get = Cookies.getJSON;

import Clipboard from 'clipboard'
window.Clipboard = Clipboard;

// 接口请求
import Api from "@/http/index";
Vue.use(Api);
window.Api = Api;

// 公共js
import Common from "@/config/utils/common";
window.Common = Common;

// ELmentUI二次封装组件
import DoUpElement from "@/config/utils/packageElement";
window.DoUpElement = DoUpElement;

//公共组件
import CommonMComs from "@/common";
Vue.use(CommonMComs);

//控制测试环境与正式环境断点续传方式  本地断点续传为：false 阿里云断点续传为：true
Vue.prototype.BreakPointUpload = true;

//公用域名
Vue.prototype.downloadURL = axios.defaults.baseURL + "/fu/front/upload/download?identifier=";
// Vue.prototype.downloadURL = axios.defaults.baseURL + `/fu/front/upload/downloadNas?tokrn=${store.state.user.token}&identifier=`;
Vue.prototype.getDownloadUrl = axios.defaults.baseURL + "/fu/front/upload/getDownloadUrl?identifier=";
// Vue.prototype.getDownloadUrl = axios.defaults.baseURL + `/fu/front/upload/getNasDownloadUrl?token=${store.state.user.token}&identifier=`;
Vue.prototype.commonbaseURL = axios.defaults.baseURL;

// 数据删除指定元素
Array.prototype.remove = function (val) {
    var index = this.indexOf(val);
    if (index > -1) {
        this.splice(index, 1);
    }
}

//不区分大小写
String.prototype.compare = function (str) {
    if (this.toLowerCase() == str.toLowerCase()) {
        return true;
    } else {
        return false;
    }
};

// encode编码
Vue.prototype.urlEncode = function objectToQueryString(obj) {
    return Object.keys(obj)
        .map(function (key) {
            return ""
                .concat(encodeURIComponent(key), "=")
                .concat(encodeURIComponent(obj[key]));
        })
        .join("&");
};

// 判断浏览器
Vue.prototype.myBrowser = function myBrowser() {
    var userAgent = navigator.userAgent; //取得浏览器的userAgent字符串
    var isOpera = userAgent.indexOf("Opera") > -1; //判断是否Opera浏览器
    var isIE = userAgent.indexOf("compatible") > -1 &&
        userAgent.indexOf("MSIE") > -1 && !isOpera; //判断是否IE浏览器
    var isEdge = userAgent.indexOf("Edge") > -1; //判断是否IE的Edge浏览器
    var isFF = userAgent.indexOf("Firefox") > -1; //判断是否Firefox浏览器
    var isSafari = userAgent.indexOf("Safari") > -1 &&
        userAgent.indexOf("Chrome") == -1; //判断是否Safari浏览器
    var isChrome = userAgent.indexOf("Chrome") > -1 &&
        userAgent.indexOf("Safari") > -1; //判断Chrome浏览器

    if (isIE) {
        var reIE = new RegExp("MSIE (\\d+\\.\\d+);");
        reIE.test(userAgent);
        var fIEVersion = parseFloat(RegExp["$1"]);
        if (fIEVersion == 7) {
            return "IE7";
        } else if (fIEVersion == 8) {
            return "IE8";
        } else if (fIEVersion == 9) {
            return "IE9";
        } else if (fIEVersion == 10) {
            return "IE10";
        } else if (fIEVersion == 11) {
            return "IE11";
        } else {
            return "0";
        } //IE版本过低
        return "IE";
    }
    if (isOpera) {
        return "Opera";
    }
    if (isEdge) {
        return "Edge";
    }
    if (isFF) {
        return "FF";
    }
    if (isSafari) {
        return "Safari";
    }
    if (isChrome) {
        return "Chrome";
    }

};
// 部署后，自动清除浏览器缓存
const VUE_APP_VERSION = require("../package.json").version
const vers = window.localStorage.getItem("pcVersion");
if (VUE_APP_VERSION != vers) {
    localStorage.removeItem('pcVersion')
    window.localStorage.setItem("pcVersion", VUE_APP_VERSION);
    location.reload();
}


//阻止 vue 在启动时生成生产提示
Vue.config.productionTip = false

Vue.use(VueRouter);
const router = new VueRouter({
    mode: 'history',
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        }
        return {
            x: 0,
            y: to.meta.savedPosition || 0,
            behavior: 'smooth',
        }
    }
});
// 重写路由push方法
const routerPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return routerPush.call(this, location).catch(error => error);
};


// 解决vuex不能存储的问题
// 监听浏览器刷新,将vuex store字符串化存储在 session中
// 在页面初始化时监听是否存在 字符串化的store
// 存在的话 replaceState 更改根节点状态 将session中的字符串解析并赋值页面刷新 存储vuex数据
if (sessionStorage.getItem("store")) {
    store.replaceState(Object.assign({}, store.state, JSON.parse(sessionStorage.getItem("store"))))
    sessionStorage.removeItem('store');
}
window.addEventListener('beforeunload', () => {
    sessionStorage.setItem("store", JSON.stringify(store.state));
})

// 判断是否登录
router.beforeEach((to, from, next) => {
    let whitelist = ['/login', '/login/third', '/login/bindAccount', '/login/forgetpwd', '/login/bindYcDingDingAccount', '/updateUserInfo', '/UpdateUserInfo','/schoolSpace','/county','/alliance','/updateUserInfoCloud','/item-list','/courseAI','/alliance2','/alliance3']
    let role = store.state.user.role;
    let token = store.state.user.token;
    let isWhite = whitelist.indexOf(to.path) != -1;
    if (!isWhite && !token) {
        next('/login');
    } else if (token && !role) {
        store.commit('getToken', undefined);
        next('/login');
    } else {
        next();
    }


})


// export default new Vue({
export default window.VueInstance = new Vue({
    router,
    store,
    render: h => h(App)
}).$mount("#app");