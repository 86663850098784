<template>
    <h3 class="project-title flex-align">
        <img :src="srcUrl" alt="" class="project-icon">
        {{this.currentProject.name}}
        <!-- <div class="jmMenu">
            <el-button type="primary" size="small" round @click="goDetail(1)">国家智慧教育公共服务平台</el-button>
            <el-button type="primary" size="small" round @click="goDetail(2)">北京智慧教育平台</el-button>
        </div> -->
    </h3>
</template>

<script>
    import {
        mapState
    } from "vuex";
    export default {
        props: {
            iconUrl: {
                type: String,
                default: 'images/admin/project-icon.png'
            }
        },
        data() {
            return {
                srcUrl: require(`@/assets/images/admin/project-icon.png`)
            };
        },
        computed: {
            ...mapState({
                currentProject: state => state.user.currentProject,
                count(state) {
                    console.log(state);
                }
            })
        },
        watch: {
            'iconUrl': {
                handler(newVal, oldVal) {
                    this.srcUrl = require(`@/assets/${this.iconUrl}`);
                },
            }
        },
        mounted() {
            // this.srcUrl = require(`@/assets/${this.iconUrl}`);
        },
        methods: {
            // goDetail(type) {
            //     if (type == 1) {
            //         window.open('https://basic.smartedu.cn/')
            //     } else {
            //         window.open('https://basic.beijing.smartedu.cn/index')
            //     }

            // }
        }
    }
</script>

<style lang="less" scoped>
    .project-title {
        margin-bottom: 30px;
        font-size: 15px;
        font-weight: bold;

        .project-icon {
            display: inline-block;
            // background-image: url("~@/assets/images/admin/project-icon.png");
            background-size: 100% 100%;
            height: 19px;
            width: 18px;
            margin-right: 12px;
            vertical-align: -3px;
        }

        .jmMenu {
            margin-left: auto;
        }
    }
</style>