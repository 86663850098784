import { render, staticRenderFns } from "./pro-title.vue?vue&type=template&id=b2f0b2be&scoped=true"
import script from "./pro-title.vue?vue&type=script&lang=js"
export * from "./pro-title.vue?vue&type=script&lang=js"
import style0 from "./pro-title.vue?vue&type=style&index=0&id=b2f0b2be&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b2f0b2be",
  null
  
)

export default component.exports