<template>
  <div class="footer flex-align-around">
    <div class="copuright">
      <!-- <span>Copyright © 2019-2022北京双优云桥教育科技有限公司</span>
      <span>yun.cnuen.net</span>
      <span>首都师范大学“双优云桥”项目组-All Rights Reserved</span> -->
      <div class="flex-align">
        <img src="@/assets/beian.png" alt="">
        <a href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010802034806" style="color:#fff;margin-right:10px">京公网安备11010802034806号</a>
        <a href="https://beian.miit.gov.cn/#/Integrated/recordQuery" style="color:#fff;margin-right:10px">京ICP备：17019635号-3</a>
      </div>
    </div>
    <img src="@/assets/qr-code.png" alt="" class="qrcode">
  </div>
</template>

<script>
  export default {
    data() {
      return {};
    },

    methods: {},
    mounted: function () {}
  };
</script>

<style lang="less" scoped>
  .footer {
    box-sizing: border-box;
    padding: 20px 160px;
    // height: 75px;
    font-size: 12px;
    background-color: #3A475B;
    ;
    .copuright{
      line-height: 25px;
      color: #fff;
      display: flex;
      flex-direction: column;
    }

    .qrcode {
      width: 100px;
    }

    @media screen and (min-width:1250px) {}
  }
</style>